:global {
  #bgVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  #dpi {
    position: absolute;
    height: 1in;
    left: -100%;
    top: -100%;
    width: 1in;
  }
  #sketch-holder {
    overflow-y: hidden;
    position: absolute;
    width: 100%;
    height: 100vh;
  }

  #TableView {
    height: 100%;
    width: 100%;
    background-color: black;
  }

  #TableView .SetupView {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
