/* Regular, Bold and Italic */
:global {
  @font-face {
    font-family: 'EON Brix Sans';
    src: url('eon_brixsans-regular-webfont.eot');
    src:
      url('eon_brixsans-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('eon_brixsans-regular-webfont.woff2') format('woff2'),
      url('eon_brixsans-regular-webfont.woff') format('woff'),
      url('eon_brixsans-regular-webfont.ttf') format('truetype'),
      url('eon_brixsans-regular-webfont.svg#eon_brix_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'EON Brix Sans';
    src: url('eon_brixsans-regularitalic-webfont.eot');
    src:
      url('eon_brixsans-regularitalic-webfont.eot?#iefix')
        format('embedded-opentype'),
      url('eon_brixsans-regularitalic-webfont.woff2') format('woff2'),
      url('eon_brixsans-regularitalic-webfont.woff') format('woff'),
      url('eon_brixsans-regularitalic-webfont.ttf') format('truetype'),
      url('eon_brixsans-regularitalic-webfont.svg#eon_brix_sansitalic')
        format('svg');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'EON Brix Sans';
    src: url('eon_brixsans-bold-webfont.eot');
    src:
      url('eon_brixsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('eon_brixsans-bold-webfont.woff2') format('woff2'),
      url('eon_brixsans-bold-webfont.woff') format('woff'),
      url('eon_brixsans-bold-webfont.ttf') format('truetype'),
      url('eon_brixsans-bold-webfont.svg#eon_brix_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'EON Brix Sans';
    src: url('eon_brixsans-bolditalic-webfont.eot');
    src:
      url('eon_brixsans-bolditalic-webfont.eot?#iefix')
        format('embedded-opentype'),
      url('eon_brixsans-bolditalic-webfont.woff2') format('woff2'),
      url('eon_brixsans-bolditalic-webfont.woff') format('woff'),
      url('eon_brixsans-bolditalic-webfont.ttf') format('truetype'),
      url('eon_brixsans-bolditalic-webfont.svg#eon_brix_sansbold_italic')
        format('svg');
    font-weight: bold;
    font-style: italic;
  }

  /* Black */
  /*

@font-face {
   font-family: 'EON Brix Sans Black';
   src: url('eon_brixsans-black-webfont.eot');
   src: url('eon_brixsans-black-webfont.eot?#iefix') format('embedded-opentype'),
        url('eon_brixsans-black-webfont.woff2') format('woff2'),
        url('eon_brixsans-black-webfont.woff') format('woff'),
        url('eon_brixsans-black-webfont.ttf') format('truetype'),
        url('eon_brixsans-black-webfont.svg#eon_brix_sansblack') format('svg');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'EON Brix Sans Black';
   src: url('eon_brixsans-blackitalic-webfont.eot');
   src: url('eon_brixsans-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
        url('eon_brixsans-blackitalic-webfont.woff2') format('woff2'),
        url('eon_brixsans-blackitalic-webfont.woff') format('woff'),
        url('eon_brixsans-blackitalic-webfont.ttf') format('truetype'),
        url('eon_brixsans-blackitalic-webfont.svg#eon_brix_sansblack_italic') format('svg');
   font-weight: normal;
   font-style: italic;
}
*/

  /* Medium */
  /*

@font-face {
   font-family: 'EON Brix Sans Medium';
   src: url('eon_brixsans-medium-webfont.eot');
   src: url('eon_brixsans-medium-webfont.eot?#iefix') format('embedded-opentype'),
        url('eon_brixsans-medium-webfont.woff2') format('woff2'),
        url('eon_brixsans-medium-webfont.woff') format('woff'),
        url('eon_brixsans-medium-webfont.ttf') format('truetype'),
        url('eon_brixsans-medium-webfont.svg#eon_brix_sansmedium') format('svg');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'EON Brix Sans Medium';
   src: url('eon_brixsans-mediumitalic-webfont.eot');
   src: url('eon_brixsans-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
        url('eon_brixsans-mediumitalic-webfont.woff2') format('woff2'),
        url('eon_brixsans-mediumitalic-webfont.woff') format('woff'),
        url('eon_brixsans-mediumitalic-webfont.ttf') format('truetype'),
        url('eon_brixsans-mediumitalic-webfont.svg#eon_brix_sansmedium_italic') format('svg');
   font-weight: normal;
   font-style: italic;

}
*/
}
