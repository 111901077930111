:global {
  .Buildings__row {
    padding: var(--spacingNarrow) 0 0 0;
    border-bottom: 2px solid var(--steelGrey);
  }

  .Building__name {
    flex-grow: 1;
    margin: 0;
    line-height: 1;
  }

  .Building__type__container {
    width: 5.5cqw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: max(14px, 0.5cqmin);
    flex-shrink: 0;
  }

  .Building__type {
    flex: 1 0 auto;
    margin: 0 var(--spacingNarrow);
    width: 5.5cqmin;
    height: 5.5cqmin;
  }

  .Building__chart {
    flex: 0 0 auto;
    margin: 0 var(--spacingNarrow) 0 0;
  }

  .Building__needs {
    flex: 0 0 auto;
    align-self: stretch;
    font-size: 1.5cqmin;
    line-height: 1;
    flex-shrink: 0;
    min-width: 20%;
  }

  .Building__need {
    margin-bottom: 0.125cqw;
    text-align: right;

    &:last-child {
      margin-bottom: 0;
    }
    & > svg {
      margin-right: 0.5cqw;
    }
  }
}
