:global {
  .Flex {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;
    overflow: hidden;
  }
  .Flex--column {
    flex-flow: column nowrap;
  }
  .Flex--row {
    flex-flow: row nowrap;
  }

  .Flex--justify--center {
    justify-content: center;
  }
  .Flex--justify--flex-end {
    justify-content: flex-end;
  }
  .Flex--justify--space-between {
    justify-content: space-between;
  }
  .Flex--justify--space-around {
    justify-content: space-around;
  }

  .Flex--alignItems--center {
    align-items: center;
  }
  .Flex--alignContent--center {
    align-content: center;
  }
}
