.singleApp,
.container_1_1,
.container_3_1,
.container_2_1_1,
.container_3_1_v {
  background: var(--surface5Color);
  width: 100%;
  height: 100%;
}

:global(.dark) {
  .singleApp,
  .container_1_1,
  .container_3_1,
  .container_2_1_1,
  .container_3_1_v {
    background: #141414;
  }
}

.container_1_1,
.container_3_1,
.container_2_1_1,
.container_3_1_v {
  display: grid;
}

.container_1_1 {
  grid-template-columns: 40% 60%;
  grid-template-rows: 100%;
  grid-template-areas: 'client1 table';
}

.hideMultiScreen {
  width: 100%;
  height: 100%;
  display: block;
  padding: 0 !important;
}

.hideMultiScreen > div {
  width: 100%;
  height: 100%;
}
.logos {
  grid-area: logo;
  display: none;
  padding: 11vh 5vh 5vh 5vh;
}

.spacer {
  width: 1px;
  flex-grow: 1;
}

.container_3_1 > .logos {
  display: flex;
}

.logos img {
  width: auto;
  height: 100%;
}

.container_3_1_v {
  padding: 0;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    'client1 table'
    'client2 table'
    'client3 table';
}

.container_3_1 {
  padding: 14vh 0vh 0vh 0vh;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 70% 30%;
  grid-template-areas:
    'client1 client2 client3 table'
    'logo logo logo logo';
}

.container_2_1_1 {
  grid-template-columns: 33.3% 33.3% 33.3%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    'client1 client2 table'
    'client3 client3 table';
}

.singleApp {
  box-sizing: border-box;
}
