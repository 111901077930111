:global {
  .Weather {
    height: 100%;
    & h3 {
      margin-bottom: var(--spacingNarrow);
    }
  }

  .Weather__title {
    position: relative;
    flex: 0 0 auto;
    padding-bottom: var(--spacingNarrow);
    margin-bottom: var(--spacing);
    border-bottom: 2px solid var(--steelGrey);
    line-height: 1;

    h2 {
      margin-bottom: 0;
    }

    & > img {
      position: absolute;
      right: 0;
      top: 50%;
      width: 3cqmin;
      transform: translateY(-50%);
    }
  }

  .Weather__section {
    margin-bottom: 2.5cqmin;
  }

  .Weather__section img {
    height: 17cqmin;
    width: auto;
  }

  .Weather__section__summary {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .Weather__section__temp {
    justify-content: space-between;
    align-items: center;
  }

  .Weather__section_value,
  .Weather__section_text {
    font-size: 1.75cqmin;
    line-height: 1;
    margin-top: 0;
    margin-bottom: calc(var(--spacingNarrow) / 2);
  }

  .Weather__section_value {
    display: inline-block;
    white-space: nowrap;
    margin-left: 0.2cqmin;
  }

  .Weather__section__summary,
  .Weather__section__temp {
    & > div:first-child {
      flex: 0 0 50%;
    }
    & > div:last-child {
      flex: 0 0 50%;
    }
  }
}
