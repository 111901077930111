:root {
  --spacing: 2.5cqw;
  --spacingNarrow: 0.5cqw;
}

:global {
  .PowerProductionView__dashboard_content__main {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .PowerProductionView__balance__comparison__item__outer__container {
    flex-grow: 1;
    text-align: center;
    min-height: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.5cqh;
    font-size: 1.7cqh;
  }

  .PowerProductionView__balance__comparison__svg__header__text {
    fill: black;
  }

  .dark .PowerProductionView__balance__comparison__svg__header__text {
    fill: white;
  }

  .PowerProductionView__dashboard_panel {
  }

  .PowerProductionView__dashboard_panel span {
    display: contents;
  }

  .PowerProductionView__dashboard_panel > div {
    height: 100%;
    width: 100%;
    text-align: center;
  }

  .PowerProductionView__dashboard_panel svg {
    width: 80%;
    height: 80%;
  }

  .PowerProductionView__dashboard_panel_container {
    flex-grow: 1;
    min-height: 0;
  }

  .PowerProductionView__balance__comparison__item__container {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    gap: 3cqw;
    min-height: 0;
    justify-content: center;
  }

  .PowerProductionView__balance__comparison__item__container > img,
  .PowerProductionView__balance__comparison__item__container > svg {
    flex: 1;
    flex-shrink: 0;
    display: block;
    width: 1px;
    max-height: 100%;
  }

  .PowerProductionView__balance__comparison__container {
    height: 100%;
    align-items: center;
    padding: 1.5cqh;
    gap: 1.5cqh;
  }

  .PowerProductionView__content__side {
    flex: 1 1 40%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .PowerProductionView__content__main {
    flex: 1 1 60%;
    & > div {
      flex: 0 0 100%;
    }

    & .Box__inner {
      margin-left: var(--spacingNarrow);
    }
  }

  .portrait {
    .PowerProductionView__balance__comparison__item__outer__container {
      height: 1px;
    }

    .PowerProductionView__balance__comparison__container {
      flex-direction: column;
    }

    .PowerProductionView__balance__comparison__item__container {
      flex-grow: 0;
    }

    .PowerProductionView__balance__comparison__container {
      align-items: stretch;
    }

    .PowerProductionView__content__main {
      padding-left: 2.5cqw;
    }
  }
}
