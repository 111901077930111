:global {
  html,
  body {
    box-sizing: border-box;
    font-family: 'EON Brix Sans', Lato, 'Helvetica Neue', Arial, Helvetica,
      sans-serif;
    margin: 0;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 16px;
    height: 100%;
    touch-action: none;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
    min-width: 320px;
    background: #000;
    line-height: 1.4285;
    color: #111;
    touch-action: none;
  }

  #root {
    height: 100%;
    width: 100vw;
    box-sizing: border-box;
  }

  #root,
  .App {
    height: 100%;
    flex-grow: 1;
  }

  .App {
    container: app / size;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1,
  .h1 {
    font-weight: bold;
    font-size: 3cqw;
    margin: 0 0 0.25cqmin;
  }

  h2,
  .h2 {
    font-weight: bold;
    font-size: 2.4cqw;
    margin: var(--spacingNarrow) 0;
  }

  h3,
  .h3 {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 2cqmin;
  }

  img {
    border-style: none;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin: 0 0 var(--spacingNarrow) / 2 0;
  }

  .l-absolute--center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  video {
    display: block;
    margin: 0 auto;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}
