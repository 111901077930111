:global {
  .number-wrapper {
    width: 100%;
  }

  .number-wrapper > .clientnr {
    font-size: 25cqmin; /* If browser supports it, use this */
    font-weight: bold;
  }
}
