:global {
  .Box {
    height: 100%;
  }

  .Box__inner {
    flex: 0 1 100%;
    margin: var(--spacingNarrow) var(--spacing) var(--spacing) var(--spacing);
    background-color: var(--whiteColor);
    box-shadow: var(--panelShadow);
    border-radius: var(--tableBorderRadius);
    border: var(--tableBorderStyle);
  }

  .dark .Box__inner {
    background-color: var(--darkBackgroundColor);
    border: 1px solid var(--darkBorderColor);
  }

  .Box__title {
    flex: 0 0 var(--spacingNarrow);

    padding: var(--smallMargin) var(--standardMargin);
    border-bottom: var(--tableBorderStyle);
  }

  .dark .Box__title {
    border-bottom: 1px solid var(--darkBorderColor);
  }

  .Box__content {
    position: relative;
    flex: 0 1 100%;
    overflow: hidden;
  }
}
