:global {
  .PowerConsumptionView__dashboard_container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .Building__type {
    filter: invert(1);
    width: 3cqw;
  }

  .dark .Building__type {
    filter: none;
  }

  .Building__chart text {
    fill: black;
  }

  .dark .Building__chart text {
    fill: white;
  }

  .PowerConsumptionView__dashboard_title_container {
    width: 100%;
    display: flex;
  }

  .PowerConsumptionView__dashboard_title {
    display: flex;
    flex-direction: column;
    flex: auto;
    flex-grow: 2;
    width: 1px;
  }

  .PowerConsumptionView__dashboard_title h1 {
    margin-bottom: 35px;
  }

  .PowerConsumptionView__eon_icon {
    max-width: 250px;
    width: 10cqw;
    height: auto;
  }

  .PowerConsumptionView__ecto_icon {
    background-image: url('../../assets/ectocloud_colored_logo.png');
    max-width: 300px;
    width: 10cqw;
    aspect-ratio: 868/321;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
  }

  .dark .PowerConsumptionView__ecto_icon {
    background-image: url('../../assets/ectocloud_colored_logo_darkmode.png');
  }

  .PowerConsumptionView__dashboard_icons {
    display: flex;
    flex-grow: 1;
    width: 1px;
    align-items: center;
  }

  .PowerConsumptionView__dashboard_icons > div {
    margin-right: 7cqw;
  }

  .PowerConsumptionView__content_center {
    display: flex;
    flex-grow: 1;
  }
  .PowerConsumptionView__center_text,
  .PowerConsumptionView__center_dashboard {
    box-sizing: border-box;
    padding: var(--spacing) var(--spacing) 0 var(--spacing);
  }

  .PowerConsumptionView__center_text {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .PowerConsumptionView__center_dashboard {
    width: 50%;
    padding-bottom: var(--spacing);
  }

  .PowerConsumptionView__center_text_columns {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .PowerConsumptionView__content__side {
    padding-left: var(--spacing);
    flex: 0 0 37%;
  }
  .PowerConsumptionView__content__main {
    flex: 1 1 63%;
  }
  .PowerConsumptionView__content__main__section {
    flex: 0 1 50%;
    height: 50%;
    padding-bottom: 2.5cqmin;

    & .Box__inner {
      margin-top: var(--spacingNarrow);
      margin-bottom: var(--spacingNarrow);
    }
  }

  .PowerConsumptionView__content_spacer {
    flex-grow: 1;
    height: 1px;
    flex-shrink: 0;
  }

  .PowerConsumptionView__center_text_columns {
    font-size: 2cqmin;
  }

  .PowerConsumptionView__content_quote {
    display: flex;
    margin-bottom: 2cqmin;
  }

  .PowerConsumptionView__content_quote img {
    max-width: 272px;
    height: auto;
    width: 14cqmin;
  }

  .PowerConsumptionView__content_quote > div {
    margin-left: 1.5cqmin;
    flex-grow: 4;
  }

  .PowerConsumptionView__content_quote > div:last-child {
    margin-right: 2cqw;
  }

  .PowerConsumptionView__quote_spacer {
    flex-grow: 1;
  }
}
