:global {
  .EctocloudEnvironment__container {
    & .clientnr {
      position: fixed;
      bottom: 0.125cqmin;
      right: 0.125cqmin;
      font-size: 0.75cqmin;
    }

    display: contents;
  }

  .dark .EctocloudEnvironment__container {
    color: var(--whiteColor);
  }
}
