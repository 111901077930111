:global {
  .recharts-legend-item svg[style] {
    vertical-align: unset !important;
  }

  .EctocloudView__content__side {
    flex: 0 0 35%;
    padding-left: var(--spacing);
    overflow: hidden;
    max-width: 50vh;
    padding-right: 2.5cqw;
  }

  .EctocloudView__prediction__x__axis {
    fill: var(--blackColor);
  }

  .dark .EctocloudView__prediction__x__axis {
    fill: white;
  }

  .EctocloudView__content__main {
    flex: 1 1 65%;
  }
  .EctocloudView__dashboard_content__main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .EctocloudView__dashboard_panel_container {
    height: 1px;
    flex-grow: 1;
    display: flex;
    padding: var(--spacing) var(--spacing) var(--spacing) var(--spacing);
    box-sizing: border-box;
  }

  .EctocloudView__dashboard_main_panel_container {
    flex-grow: 3;
    width: 1px;
  }

  .EctocloudView__dashboard_side_panel_container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    max-width: 700px;
    flex-grow: 1.5;
    width: 1px;
  }

  .EctocloudView__dashboard_panel_container .highcharts-axis-labels text {
    font-size: 2cqmin;
  }

  .EctocloudView__dashboard_panel_container .highcharts-xaxis-labels text {
    font-size: min(2cqmin, 26px) !important;
    transform: translate(0, 10px) !important;
  }

  .EctocloudView__dashboard_panel_container svg text {
    font-size: 2cqmin !important;
  }

  .EctocloudView__dashboard_panel_container .highcharts-background {
    display: none;
  }

  .EctocloudView__side_panel {
    height: calc(100% / 3);
    box-sizing: border-box;
    padding-left: var(--largeMargin);
    padding-bottom: var(--largeMargin);
    font-size: 16px;
  }

  .EctocloudView__side_panel:last-child {
    padding-bottom: 0;
  }

  .portrait {
    .EctocloudView__side_panel:first-child {
      padding-left: 0;
    }

    .EctocloudView__dashboard_panel_container {
      flex-direction: column;
    }

    .EctocloudView .EctocloudView__content__side {
      flex: unset;
      max-width: none;
      flex-shrink: 0;
      height: 1;
    }
    .EctocloudView .EctocloudView__content__main {
      flex: unset;
      flex: 1;
      height: 0;
    }

    .EctocloudView .View__content {
      flex-direction: column;
    }

    .EctocloudView .WeatherItems {
      flex-direction: row;
      justify-content: space-between;
      & > div {
        width: 50%;
      }

      & > div:first-child {
        margin-right: 2.5cqmin;
      }
    }

    .EctocloudView__side_panel {
      height: 100%;
      width: 1px;
      flex-grow: 1;
      padding-bottom: 0;
    }

    .EctocloudView__dashboard_main_panel_container {
      width: 100%;
      height: 1px;
    }

    .EctocloudView__dashboard_side_panel_container {
      max-width: none;
      padding-top: 2cqh;
      height: 1px;
      width: 100%;
      flex-direction: row;
      flex-grow: 1;
    }
  }
}
