:global {
  .InfoView {
    align-items: center;
    font-size: 70px;
  }

  .InfoViewFullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
  }
  .InfoViewFullScreenContainer {
    width: 100vw;
    height: 100vh;
    padding: 20vh;
  }

  .InfoViewImage > div {
    flex-grow: 1;
    width: 100%;
  }

  .InfoView img {
    height: auto;
  }

  .InfoView > .View__content {
    justify-content: center;
  }

  .InfoViewFullscreenControls__menu {
    bottom: 10px;
    background: black;
    font-size: 2vh;
    box-sizing: border-box;
    padding: 1vh;
    max-width: 35vh;
    white-space: nowrap;
    visibility: hidden;
  }

  .InfoViewFullscreenControls__menu__item {
    display: block;
    padding: 1vh;
    cursor: pointer;
  }

  .InfoViewFullscreenControls__menu__item.selected {
    color: rgb(90, 180, 90);
  }

  .InfoViewFullscreenControls__menu.open {
    visibility: visible;
  }

  .InfoViewFullscreenControls {
    position: absolute;
    right: 2vh;
    bottom: 22vh;
    display: flex;
    justify-content: flex-end;
    max-width: 35vh;
  }

  .InfoViewFullscreenControls > button {
    width: 7vh;
    height: 7vh;
    background: transparent;
    border: 0;
    border-bottom: 0.5vh solid transparent;
  }

  .InfoViewFullscreenControls > button.open {
    border-bottom: 0.5vh solid rgb(90, 180, 90);
  }

  .InfoViewFullscreenControls > button + button {
    margin-left: 5vh;
  }
}
