:global {
  .SetupView {
    background-image: none;
    background-color: #0d0d0d;
    text-align: center;
    padding: var(--spacing);

    & .View__content {
      justify-content: center;

      & > p {
        margin-top: var(--spacingNarrow);
        font-size: 1.75cqmin;
        color: #c4c4c4;
        padding-bottom: var(--spacingNarrow);
      }
    }
  }

  .row {
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-content: flex-start;
    align-items: stretch;
    justify-content: space-around;
  }
  .col {
    overflow: hidden;
    flex: 0 1 60cqmin;
    margin: var(--spacingNarrow);
  }

  .col--keypad {
    margin-top: var(--spacing);
  }

  .SetupView__desc {
  }

  .screen-pane {
    position: relative;
    padding: var(--spacing) var(--spacing);
    text-align: center;
    border: 3px rgba(59, 242, 176, 0) solid;
    border-radius: 5px;
    background-color: #1f1f1f;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: var(--spacing);

    &.screen-pane--active {
      border-color: rgba(59, 242, 176, 0.8);
      background-color: transparent;
    }
    &.screen-pane--error {
      & .screen-pane__status {
        color: #f23b3b;
      }
      & .screen-pane__number {
        border-color: #f23b3b;
      }
    }
    &.screen-pane--activated {
      & .screen-pane__status {
        color: rgba(59, 242, 176, 1);
      }
      & .screen-pane__number {
        border-color: rgba(59, 242, 176, 1);
      }
    }

    h2 {
      font-size: 2.5cqmin;

      font-weight: normal;
      flex: 0 0 auto;
      color: #f8f8f8;
    }

    & .screenshot {
      overflow: hidden;
      flex: 0 1 auto;

      & img {
        max-width: 100%;
        height: auto;
      }
    }

    & > .screen-pane__numbers {
      flex: 0 1 12cqmin;
      width: 100%;
      justify-content: center;
      align-items: stretch;
      align-content: center;
      margin: 3cqmin 0;
      padding: 0.6cqmin;
    }

    & .screen-pane__number {
      flex: 1 1 6cqmin;
      min-width: 0.9cqmin;
      border: 2px #f8f8f8 solid;
      border-radius: 1px;
      background-color: rgba(28, 28, 28, 0.75);
      font-size: 5.5cqmin;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-child {
      }

      &.screen-pane__number--active {
        background-color: rgba(59, 242, 176, 0.8);
      }
    }
  }
  .screen-pane__status {
    font-size: 1.75cqmin;
    line-height: 2;
    position: absolute;
    bottom: -0.25cqw;

    & > img {
      display: inline-block;
      padding-right: 0.25cqw;
      height: 2.5cqmin;
      width: 2.5cqmin;
      transform: translateY(0.1875cqw);
    }
  }

  .keypad {
    border: 3px rgba(59, 242, 176, 0) solid;
    border-radius: 5px;
    background-color: #1f1f1f;

    & > .Flex--row {
      justify-content: space-around;
      align-content: center;
      align-items: center;
      padding: var(--spacingNarrow) var(--spacing);
    }

    & button {
      font-size: 2.5cqmin;
      flex: 0 1 1cqmin;
      margin: var(--spacingNarrow);
      padding: 0;
      background: none;
      border: 0;
      color: white;
      position: relative;

      &:not([disabled]) {
        &:hover,
        &:focus,
        &:active {
          background-color: rgba(248, 248, 248, 0.44);
        }
      }
      & > img {
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
        max-height: 7cqmin;
      }
    }
  }

  .button {
    display: block;
    font-size: 2.5cqmin;
    line-height: 2;
    border: 0;
    background: rgba(59, 242, 176, 0.75);
    color: white;
    border-radius: 5px;
    margin: var(--spacingNarrow) auto 3.75cqw;
    padding: 0 var(--spacingNarrow);

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        border-color: rgba(4, 194, 97, 0.75);
        background-color: rgba(4, 194, 97, 0.75);
      }
    }
  }

  @media screen and (max-height: 1921px) {
    .SetupView {
      padding: 0 5cqmin;

      & .View__content {
        & > h1 {
          padding: 0;
          margin: 0;
        }

        & > p {
          padding-bottom: 0;
          margin-bottom: var(--spacingNarrow);
        }
      }
    }

    .col {
      margin: var(--spacingNarrow);
    }

    .screen-pane {
      padding: 0 var(--spacingNarrow);

      h2 {
        margin-bottom: 0.25cqw;
        margin-top: 0.25cqw;
        padding-bottom: 0;
      }

      & > .screen-pane__numbers {
        flex: 0 1 9cqmin;
        width: 100%;
        margin: 0.25cqw 0 3cqmin 0;
      }
      & .screen-pane__number {
        max-width: 7cqmin;
        line-height: 1.25;
        font-size: 5cqmin;
      }
    }

    .screen-pane__status {
      font-size: 1.75cqmin;
      line-height: 1.75cqmin;
      position: absolute;
      bottom: -0.125cqw;

      & > img {
        padding-right: 0.1875cqw;
        height: 1.75cqmin;
        width: 1.75cqmin;
        transform: translateY(0.1875cqw);
      }
    }

    .keypad {
      & > .Flex--row {
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 0.1cqw;
      }
      & button {
        font-size: 3cqmin;
        line-height: 9cqmin;
        flex-basis: 9cqmin;
        margin: 0;

        & > img {
          max-height: 4cqmin;
        }
      }
    }

    .button {
      margin: 0 auto var(--spacingNarrow);
    }
  }

  @media (max-width: 1200px) {
    .row {
      max-width: 100vw;
    }
  }

  @media screen and (max-height: 721px) {
    .SetupView {
      padding: 0 16px;
    }
  }
}
