:global {
  .View {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    margin: 0 auto;
    overflow: hidden;
    background: transparent;
    color: #363636;

    & > .clientnr {
      position: fixed;
      bottom: 0.09375cqmin;
      right: 0.09375cqmin;
      color: rgba(255, 255, 255, 0.25);
      font-size: 0.75cqmin;
    }
  }

  .dark .View {
    color: var(--whiteColor);
  }

  .View__title {
    padding: var(--spacing) var(--spacing) 0 var(--spacing);
    flex: 0 0 3vmin;
    display: flex;
    align-items: baseline;
    margin-bottom: 2vh;
  }

  .View__content {
    flex: 0 1 100%;

    a {
      display: block;
      color: white;
    }
  }

  .View__centerSpinnerContainer {
    position: absolute;
    top: 4cqmin;
    left: 50%;
  }
}
